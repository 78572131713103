"use client"
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Head from 'next/head';
import Script from 'next/script';
import { Container, Row, Col, Badge, Alert } from 'react-bootstrap';
import Select from 'react-select';
import * as Icon from 'react-feather';

import Sidebar from '../components/sidebar';
import Header from '../components/header';
import Footer from '../components/footer';
import BasicFundCard from '../components/BasicFundCard';
import Search from '../components/search';
import Mobile from '../components/mobile';

export default function Home({ data, yukselenFonlar, dusenFonlar, yukselenFonlarBes, dusenFonlarBes, initialTemettuSampiyonlari, yillar, bloglar }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(data.baslangicYil);
  const [temettuSampiyonlari, setTemettuSampiyonlari] = useState(initialTemettuSampiyonlari || []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  }

  useEffect(() => {
    const fetchTemettuSampiyonlari = async () => {
      try {
        const response = await fetch(`https://api.yatirimnedir.com/temettu-sampiyonlari.php?yil=${selectedOption}`);
        const jsonData = await response.json();
        setTemettuSampiyonlari(jsonData.temettuSampiyonlari);
      } catch (error) {
        console.error('API isteğinde hata:', error);
      }
    };

    if (selectedOption) {
      fetchTemettuSampiyonlari();
    }
  }, [selectedOption]);

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption.value);
  }

  return (
    <>
      <Head>
        <title>Temettü Hisseleri - Yatırım Fonları - BES Fonları Analiz | Yatırım Nedir?</title>
        <meta name="description" content="Yatırım Nedir? sitesi, yatırım fonları, Bes Fonları ve Temettü hisselerini tek bir siteden analiz edebileceğiniz ücretsiz tek site." key="desc" />
        <meta property="og:title" content="Temettü Hisseleri - Yatırım Fonları - BES Fonları Analiz | Yatırım Nedir?" />
        <meta
          property="og:description"
          content="Yatırım Nedir? sitesi, yatırım fonları, Bes Fonları ve Temettü hisselerini tek bir siteden analiz edebileceğiniz ücretsiz tek site."
        />
        <meta name="google-adsense-account" content="ca-pub-8157147392800140" />
      </Head>
      <Script
        async="true"
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8157147392800140"
        strategy="afterInteractive"
        crossorigin="anonymous"
      />
      <div className="app-root d-flex flex-column">
        <Sidebar 
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />

        <main className='app-main'>
          <Header
            isOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
          
          <div className='app-content pt-3 pt-lg-4 px-0 px-lg-2'>
            <Container>
              <Mobile />
              <Row className='mb-4'>
                <Col>
                  <div className='bg-sidebar rounded p-4 p-lg-5 text-center'>
                    <h1 className='text-white h3 mb-5 mt-3'>
                      <p>Yatırım Nedir¿</p>
                      <p className='mb-0'>Türkiye’nin En Gelişmiş, Ücretsiz</p>
                      <p className='lh-base'><Link prefetch={false} href="/temettu-hisseleri" className='bg-white text-primary px-2 rounded text-decoration-none'>Temettü</Link>, <Link prefetch={false} href="/genel-fon-analiz" className='bg-white text-primary px-2 rounded text-decoration-none'>Yatırım Fonu</Link> ve <Link prefetch={false} href="/genel-bes-fon-analiz" className='bg-white text-primary px-2 rounded text-decoration-none'>BES Fonu</Link> Analiz Platformu</p>
                    </h1>
                    <p className='text-white font-small opacity-50 mb-3'>Ücretsiz analiz etmeye başlamak için aşağıdaki arama kutusuna temettü hissesi veya fon kodu yazın.</p>
                    <Row className='mb-3'>
                      <Col lg={{ span: 6, offset: 3 }}>
                        <Search />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row className='mb-4 text-center'>
                <Col>
                  <Link prefetch={false} href={data.bannerWebUrl} className='d-none d-lg-block d-xl-block'>
                    <Image src={data.bannerWebImg} target="_blank" width='1300' height='250' className='img-fluid rounded' quality={75} />
                  </Link>
                  <Link prefetch={false} href={data.bannerMobileUrl} target="_blank" className='d-block d-lg-none'>
                    <Image src={data.bannerMobileImg} width='300' height='250' className='img-fluid rounded' quality={75} />
                  </Link>
                </Col>
              </Row>

              <Row className='mb-2'>
                <Col lg={12} className='mb-2'>
                  <h2 className='h4'>Yatırım Nedir Platformunda Bulunan Yatırım Araçları</h2>
                </Col>
                <Col lg={4} md={12} className='mb-4'>
                  <div className='card-shadow p-4 text-center'>
                    <strong className='font-small text-heading d-block mb-3'>TEMETTÜ HİSSESİ</strong>
                    <h3 className='h1 mb-4'>{data.temettuHissesiSayisi}</h3>
                    <p className='font-small mb-4'>Temettü yatırımcıları için Borsa İstanbul üzerindeki tüm temettü hisselerini belirli aralıklarla analiz ediyor, temettü performanslarını ölçüyor.</p>
                    <Link prefetch={false} href="/temettu-hisseleri" className="btn btn-primary px-4">Tümünü İncele</Link>
                  </div>
                </Col>
                <Col lg={4} md={12} className='mb-4'>
                  <div className='card-shadow p-4 text-center'>
                    <strong className='font-small text-heading d-block mb-3'>YATIRIM FONU</strong>
                    <h3 className='h1 mb-4'>{data.yatirimFonuSayisi}</h3>
                    <p className='font-small mb-4'>Tefas’ta işlem gören ve görmeyen tüm yatırım fonları, sistemimiz tarafından sürekli analiz ediliyor ve seçim yapmanız için sizlere yardımcı oluyor.</p>
                    <Link prefetch={false} href="/genel-fon-analiz" className="btn btn-primary px-4">Tümünü İncele</Link>
                  </div>
                </Col>
                <Col lg={4} md={12} className='mb-4'>
                  <div className='card-shadow p-4 text-center'>
                    <strong className='font-small text-heading d-block mb-3'>BES FONU</strong>
                    <h3 className='h1 mb-4'>{data.besFonuSayisi}</h3>
                    <p className='font-small mb-4'>BES yatırımı yaparken en önemli olan şey fon seçimi. Fon seçimi yapmanızı kolaylaştırmak için sistemimiz sürekli analiz yapar ve BES fonlarını puanlar.</p>
                    <Link prefetch={false} href="/genel-bes-fon-analiz" className="btn btn-primary px-4">Tümünü İncele</Link>
                  </div>
                </Col>
              </Row>

              <Row className='mb-2'>
                <Col lg={12} className='mb-2'>
                  <h2 className='h4'>Yatırım Fonları Günlük İstatistikleri</h2>
                </Col>
                <Col lg={6} md={12} className='mb-4'>
                  <BasicFundCard
                    title='Dünün En Çok Yükselen <ins>Yatırım Fonları</ins>'
                    trend='up'
                    button={true}
                    buttonText='Tümünü Keşfet'
                    buttonURL='/genel-fon-analiz'
                    funds={yukselenFonlar}
                  />
                </Col>
                <Col lg={6} md={12} className='mb-4'>
                  <BasicFundCard
                    title='Dünün En Çok Düşen <ins>Yatırım Fonları</ins>'
                    trend='down'
                    button={true}
                    buttonText='Tümünü Keşfet'
                    buttonURL='/genel-fon-analiz'
                    funds={dusenFonlar}
                  />
                </Col>
              </Row>

              <Row className='mb-2'>
                <Col lg={12} className='mb-2'>
                  <h2 className='h4'>BES Fonları Günlük İstatistikleri</h2>
                </Col>
                <Col lg={6} md={12} className='mb-4'>
                  <BasicFundCard
                    title='Dünün En Çok Yükselen <ins>BES Fonları</ins>'
                    trend='up'
                    button={true}
                    buttonText='Tümünü Keşfet'
                    buttonURL='/genel-bes-fon-analiz'
                    funds={yukselenFonlarBes}
                  />
                </Col>
                <Col lg={6} md={12} className='mb-4'>
                  <BasicFundCard
                    title='Dünün En Çok Düşen <ins>BES Fonları</ins>'
                    trend='down'
                    button={true}
                    buttonText='Tümünü Keşfet'
                    buttonURL='/genel-bes-fon-analiz'
                    funds={dusenFonlarBes}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={12} className='mb-2'>
                  <h2 className='h4'>Temettü Hisseleri</h2>
                </Col>
                <Col className='mb-3'>
                  <div className='card-shadow'>
                    <div className='p-4 border-bottom d-flex align-items-center justify-content-between'>
                      <h2 className='h6 m-0'>{selectedOption} Temettü Şampiyonları</h2>
                      <Select
                        key='yillar'
                        onChange={handleChange}
                        options={yillar}
                        defaultValue={[yillar[1]]}
                        isSearchable={false}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#F8F9FA',
                            primary: '#5151F9',
                          },
                        })}
                        styles={{
                          singleValue: provided => ({
                            ...provided,
                            color:'#9499A1',
                            fontSize: '14px',
                            letterSpacing: '0.42px'
                          }),
                        }}
                      />
                    </div>
                    <div className='p-4 pt-0'>
                      <Alert variant='info' className='mt-4 mb-0 w-100 show-sm'>
                        Tabloyu yana kaydırabilirsiniz. <Icon.ArrowRight className='ms-1' size={16}/>
                      </Alert>
                      <div className="table-responsive text-nowrap">
                        <table className="table table-border-dashed font-small">
                          <thead className='text-color'>
                            <tr>
                              <th scope="col" className='fw-500 text-color'>HİSSE</th>
                              <th scope="col" className='fw-500 text-color'>PAY BAŞI</th>
                              <th scope="col" className='fw-500 text-color'>TEMETTÜ VERİMİ</th>
                              <th scope="col" className='fw-500 text-color'>TEMETTÜ SAYISI</th>
                            </tr>
                          </thead>
                          <tbody>
                            {initialTemettuSampiyonlari && temettuSampiyonlari.map((sampiyon, index) => (
                              <tr key={index}>
                                <td className='ps-0'>
                                  <Link prefetch={false} href={`hisse-analiz/${sampiyon.seflink}`} className="text-decoration-none text-heading">
                                    <Image
                                      src={`https://api.yatirimnedir.com/logos/${sampiyon.kod}.png`}
                                      alt={sampiyon.hisse_adi}
                                      quality={50}
                                      width={40}
                                      height={40}
                                      className='rounded me-2'
                                    />
                                    <div className='item-icon-2 me-3'><span>{sampiyon.kod}</span></div>
                                    <span>{sampiyon.hisse_adi}</span>
                                  </Link>
                                </td>
                                <td>
                                  <strong className='text-success'>{sampiyon.pay_basi}</strong>
                                </td>
                                <td>
                                  <strong className='text-success'>{sampiyon.verim}</strong>
                                </td>
                                <td>
                                  <strong className='text-success'>
                                    <Badge 
                                      pill 
                                      bg={sampiyon.temettu_sayisi > 1 ? "success" : "warning"}
                                    >
                                      {sampiyon.temettu_sayisi}
                                    </Badge>
                                  </strong>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <Link prefetch={false} href="/temettu-hisseleri" className="btn btn-primary d-block">Tüm Hisseleri İncele</Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className='mb-4'>
                <Col>
                  <div className='card-shadow'>
                    <div className='p-4 pb-1'>
                      <h1 className='h3'>Yatırım Nedir?</h1>
                      <p>Kasım 2023 yılında YouTube kanalımız <Link prefetch={false} href={`https://www.youtube.com/@YatirimNedir`} target='_blank'>YatırımNedir¿</Link> ile çıktığımız bu yolculukta, küçük yatırımcıya uzun vadeli yatırımcılı, doğru analiz etmenin önemini, kısaca yatırım nedir? kelimesinin anlamını anlatmaya çalışıyoruz.</p>
                      <p>Mesleğimin yazılımcılık olması sebebi ile takipçilemizden gelen istekler doğrultusunda Yatırım Nedir? sitesi ortaya çıktı, ardından sitemizin bir <Link prefetch={false} href="/mobile-app">mobil uygulama</Link> versiyonunu yayınladık.</p>
                      <p>Şimdilerde yeni finans yazılım ve uygulamaları üzerinde çalışırken bir yandan da bu projeyi geliştirmeye devam ediyorum.</p>
                      <p>Projemizi desteklemek veya işbirliği yapmak için <Link prefetch={false} href={`mailto:isbirligi@yatirimnedir.com`} target='_blank'>isbirligi@yatirimnedir.com</Link> adresinden iletişime geçebilirsiniz.</p>
                    </div>
                  </div>  
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </main>
      </div>
    </>
  );
}

export async function getServerSideProps(context) {
  async function fetchData() {
    const response = await fetch('https://api.yatirimnedir.com/site-anasayfa.php');
    const jsonData = await response.json();
    return jsonData;
  }

  const [data] = await Promise.all([fetchData()]);

  const yukselenFonlar = data.dunYukselenFonlar.map(fon => ({
    name: fon.kod,
    value: fon.fon_adi,
    percentage: fon.getiri,
    url: `fon-analiz/${fon.seflink}`
  }));

  const dusenFonlar = data.dunDusenFonlar.map(fon => ({
    name: fon.kod,
    value: fon.fon_adi,
    percentage: fon.getiri,
    url: `fon-analiz/${fon.seflink}`
  }));

  const yukselenFonlarBes = data.dunYukselenBesFonlar.map(fon => ({
    name: fon.kod,
    value: fon.fon_adi,
    percentage: fon.getiri,
    url: `bes-fon-analiz/${fon.seflink}`
  }));

  const dusenFonlarBes = data.dunDusenBesFonlar.map(fon => ({
    name: fon.kod,
    value: fon.fon_adi,
    percentage: fon.getiri,
    url: `bes-fon-analiz/${fon.seflink}`
  }));

  const yillar = data.yillar.map(yil => ({
    value: yil.id,
    label: yil.id,
  }));

  const blogListesi = data.blog.map(bl => ({
    tarih: bl.tarih,
    baslik: bl.baslik,
    seflink: bl.seflink,
    icerik: bl.icerik,
    foto: bl.foto,
    url: `blog-detay/${bl.seflink}`
  }));

  const initialTemettuSampiyonlari = data.temettuSampiyonlari.map(hisse => ({
    kod: hisse.kod,
    hisse_adi: hisse.hisse_adi,
    pay_basi: hisse.pay_basi,
    verim: hisse.verim,
    ortalama_dagitma_orani: hisse.ortalama_dagitma_orani,
    temettu_sayisi: hisse.temettu_sayisi,
    seflink: hisse.seflink
  }));

  return {
    props: {
      data,
      yukselenFonlar,
      dusenFonlar,
      yukselenFonlarBes,
      dusenFonlarBes,
      yillar,
      initialTemettuSampiyonlari,
      bloglar: blogListesi
    },
  };
}